@font-face {
    font-family: 'NeoSansProRegular';
    src: local('NeoSansProRegular'), url("../src/assets/fonts/NeoSansProRegular.OTF") format('opentype');
}

@font-face {
    font-family: 'NeoSansProBold';
    src: local('NeoSansProBold'), url("../src/assets/fonts/NeoSansProBold.OTF") format('opentype');
}

@font-face {
    font-family: 'NeoSansProLight';
    src: local('NeoSansProLight'), url("../src/assets/fonts/NeoSansProLight.OTF") format('opentype');
}

body {
    overflow-x: hidden;
}

sup {
    vertical-align: top;
    font-size: 0.7em;
}

sub {
    font-size: 0.7em;
}

/* https://github.com/recharts/recharts/issues/172 */
.recharts-wrapper {
    position: absolute !important;
}

.fade-anim {
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* https://codepen.io/nelledejones/pen/gOOPWrK */
.gelatine {
    animation: gelatine 5s infinite;
}
@keyframes gelatine {
    from, to { transform: scale(1, 1); }
    2.5% { transform: scale(0.9, 1.1); }
    5% { transform: scale(1.1, 0.9); }
    7.5% { transform: scale(0.95, 1.05); }
    10% { transform: scale(1, 1); }
}

.overview-fade {
    animation-duration: 0.75s;
    animation-name: fadeInAnimation;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
}

.overview-fade.scale {
    animation-name: scaleAnimation;
    animation-timing-function: ease;
}

@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(0.8, 0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.delay-1 {
    animation-delay: 0.1s;
}
.delay-2 {
    animation-delay: 0.2s;
}
.delay-3 {
    animation-delay: 0.3s;
}
